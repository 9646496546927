import { IDoorProps } from '@ovl/advent-cal/domain';
import React, { useState } from 'react';
import Door from '../door/door';
import './masonry.scss';

interface IProps {
  doors: IDoorProps[];
  openDoor: (doorId: string) => void;
}

export const Masonry = ({ doors, openDoor }: IProps) => {
  const [animating, setAnimating] = useState<string>();

  return (
    <div className="ovl-adventMasonry">
      {doors.map((door, idx) => (
        <div className="ovl-adventMasonry__item" key={idx}>
          <Door
            door={door}
            openDoor={openDoor}
            animating={animating}
            doAnimate={(id: string) => setAnimating(id)}
          />
        </div>
      ))}
    </div>
  );
};

export default Masonry;
