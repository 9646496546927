import { IDoorProps } from '@ovl/advent-cal/domain';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SnowStorm from 'react-snowstorm';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useMedia } from 'use-media';
import Footer from '../footer/footer';
import './calendar.scss';
import { ReactComponent as CloseIcon } from './close.svg';
import DoorContent from './door-content/door-content';
import Masonry from './masonry/masonry';
import Terms from './terms/terms';

interface IProps {
  backgroundImg: string;
  doors: IDoorProps[];
  onDoorOpen: (doorId: string) => void;
  onClaimPrize: (doorId: string) => void;
  setShowCalendar: (show: boolean) => void;
}

export const Calendar = ({
  backgroundImg,
  doors,
  onDoorOpen,
  onClaimPrize,
  setShowCalendar,
}: IProps) => {
  const backDropRef = useRef<HTMLDivElement>(null);
  const simpleScrollRef = useRef<HTMLDivElement>(null);
  const [openDoorId, setOpenDoorId] = useState<string>();
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const isDesktop = useMedia({ minWidth: '1024px' });

  const onClaim = (doorId: string) => {
    onClaimPrize(doorId);

    if (simpleScrollRef.current?.scrollTop) {
      simpleScrollRef.current.scrollTop = 0;
    }
  };

  const onOpenDoor = (doorId: string) => {
    setOpenDoorId(doorId);
    onDoorOpen(doorId);
  };

  const closeOnBackdrop = (event) => {
    if (event.target === backDropRef.current) {
      setShowCalendar(false);
    }
  };

  const openDoor = useMemo(() => {
    return doors.find((d) => d._id === openDoorId);
  }, [doors, openDoorId]);

  useEffect(() => {
    if (simpleScrollRef.current?.scrollTop) {
      simpleScrollRef.current.scrollTop = 0;
    }
  }, [showTerms, openDoorId]);

  return (
    <div
      className="ovl-adventCalendar"
      onClick={closeOnBackdrop}
      ref={backDropRef}
    >
      <SnowStorm
        followMouse={false}
        excludeMobile={true}
        targetElement={backDropRef.current}
      />

      <div
        className="ovl-adventCalendar__frame"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <div className="ovl-adventCalendar__frameOpacity">
          <button
            className="ovl-adventCalendar__close"
            onClick={() => setShowCalendar(false)}
          >
            <CloseIcon />
          </button>

          <div className="ovl-adventCalendar__title">
            <h2>Adventkalender 2020</h2>
          </div>

          <div className="ovl-adventCalendar__content">
            <SimpleBar
              scrollableNodeProps={{ ref: simpleScrollRef }}
              style={{ height: '100%' }}
            >
              {!showTerms && !!openDoorId && (
                <DoorContent
                  door={openDoor}
                  onClaimPrize={onClaim}
                  close={() => setOpenDoorId(null)}
                  openTerms={() => setShowTerms(true)}
                />
              )}

              {!showTerms && !openDoorId && (
                <Masonry doors={doors} openDoor={onOpenDoor} />
              )}

              {showTerms && <Terms close={() => setShowTerms(false)} />}

              {!isDesktop && (
                <Footer setShowTerms={setShowTerms} showTerms={showTerms} />
              )}
            </SimpleBar>

            {isDesktop && (
              <Footer setShowTerms={setShowTerms} showTerms={showTerms} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
