import { AdventConfigDto, IDoorProps } from '@ovl/advent-cal/domain';
import React, { useEffect, useState } from 'react';
import { Calendar } from '../calendar';
import './advent-cal.scss';

export interface ILocalInfo {
  openedDoors: string[];
  claimedPrizes: string[];
}

const LOCAL_STORAGE_KEY = 'ovl-advent-calendar-info';

const setLocalInfo = (info: ILocalInfo) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(info));
  } catch (error) {}
};

const getLocalInfo = (): ILocalInfo | null => {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    return null;
  }
};

interface IProps {
  calendarConfig: AdventConfigDto;
  Toggle: React.FunctionComponent<{ setShowCalendar: (show: boolean) => void }>;
}

export const AdventCal = ({ calendarConfig, Toggle }: IProps) => {
  const localInfo = getLocalInfo();
  const [showCalendar, setShowCalendar] = useState(false);
  const [openedDoors, setOpenedDoors] = useState<string[]>([]);
  const [claimedPrizes, setClaimedPrizes] = useState<string[]>([]);

  useEffect(() => {
    setOpenedDoors(localInfo?.openedDoors ?? []);
    setClaimedPrizes(localInfo?.claimedPrizes ?? []);
  }, [localInfo]);

  const onDoorOpen = (doorId: string) => {
    const updatedOpenDoors = localInfo?.openedDoors
      ? [...localInfo.openedDoors, doorId].filter(
          (value, index, self) => self.indexOf(value) === index
        )
      : [doorId];

    setLocalInfo({
      ...localInfo,
      openedDoors: updatedOpenDoors,
    });

    setOpenedDoors(updatedOpenDoors);
  };

  const onClaimPrize = (doorId: string) => {
    const updatedClaimedPrizes = localInfo?.claimedPrizes
      ? [...localInfo.claimedPrizes, doorId].filter(
          (value, index, self) => self.indexOf(value) === index
        )
      : [doorId];

    setLocalInfo({
      ...localInfo,
      claimedPrizes: updatedClaimedPrizes,
    });

    setClaimedPrizes(updatedClaimedPrizes);
  };

  const doors: IDoorProps[] = calendarConfig.doors.map((door) => {
    return {
      ...door,
      wasOpened: openedDoors?.includes(door._id) ?? false,
      prizeClaimed: claimedPrizes?.includes(door._id) ?? false,
    };
  });

  return (
    <div className="ovl-advent">
      <Toggle setShowCalendar={setShowCalendar} />

      {showCalendar && (
        <Calendar
          backgroundImg={calendarConfig.backgroundImg}
          doors={doors}
          onDoorOpen={onDoorOpen}
          onClaimPrize={onClaimPrize}
          setShowCalendar={setShowCalendar}
        />
      )}
    </div>
  );
};

export default AdventCal;
