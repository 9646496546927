import { IDoorProps } from '@ovl/advent-cal/domain';
import React, { useEffect, useState } from 'react';
import Form from '../form/form';
import { ReactComponent as CopyIcon } from './copy.svg';
import './door-content.scss';

interface IProps {
  door: IDoorProps;
  onClaimPrize: (doorId: string) => void;
  openTerms: () => void;
  close: () => void;
}

export const DoorContent = ({
  door,
  onClaimPrize,
  close,
  openTerms,
}: IProps) => {
  const [animateIn, setAnimateIn] = useState(false);
  const [showCopyHint, setShowCopyHint] = useState(false);
  const { prizeClaimed } = door;

  useEffect(() => {
    setAnimateIn(true);
  }, []);

  const onSubmitForm = (data: { [key: string]: string | boolean }) => {
    console.log(data);

    // TODO: add api call and store form fields in local storage to pre fill fields on next visit
    onClaimPrize(door._id);
  };

  const handleCodeCopy = (code: string) => {
    try {
      navigator.clipboard.writeText(code);
      setShowCopyHint(true);

      setTimeout(() => {
        setShowCopyHint(false);
      }, 1000);
    } catch (error) {}
  };

  return (
    <div
      className="ovl-adventDoorContent"
      style={{ opacity: animateIn ? 1 : 0 }}
    >
      <div className="ovl-adventDoorContent__img">
        {!prizeClaimed && (
          <img src={door.content.imgUrl} alt={`Bild von Tür #${door.number}`} />
        )}
        {prizeClaimed && (
          <img
            src={door.content.imgClaimedUrl}
            alt={`Bild von Tür #${door.number}`}
          />
        )}
        <div className="ovl-adventDoorContent__imgDesc">
          {door.number}. Dezember
        </div>
      </div>

      <div className="ovl-adventDoorContent__body">
        {!prizeClaimed && (
          <>
            <h2>{door.content.title}</h2>

            {!!door.content.text && <p>{door.content.text}</p>}

            <Form door={door} onSubmit={onSubmitForm} openTerms={openTerms} />
          </>
        )}

        {prizeClaimed && (
          <>
            <h2>{door.content.titleClaimed}</h2>

            {!!door.content.textClaimed && <p>{door.content.textClaimed}</p>}

            {!!door.content.code && (
              <div className="ovl-adventDoorContent__code">
                <div className="ovl-adventDoorContent__codeField">
                  <div>{door.content.code}</div>
                  <small style={{ opacity: showCopyHint ? 1 : 0 }}>
                    In Zwischenablage kopiert!
                  </small>

                  <button onClick={() => handleCodeCopy(door.content.code)}>
                    <CopyIcon />
                  </button>
                </div>
              </div>
            )}

            {!!door.content.smallTextClaimed && (
              <p>
                <small>{door.content.smallTextClaimed}</small>
              </p>
            )}

            {!!door.content.cta && (
              <div className="ovl-adventDoorContent__cta">
                <a
                  className="ovl-advent__cta"
                  href={door.content.cta.url}
                  target={door.content.cta.target}
                  rel={door.content.cta.rel}
                >
                  {door.content.cta.label}
                </a>
              </div>
            )}
          </>
        )}

        <div className="ovl-adventDoorContent__close">
          <button onClick={close}>Zurück zum Kalender</button>
        </div>
      </div>
    </div>
  );
};

export default DoorContent;
