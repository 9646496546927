import { AdventConfigDto } from '@ovl/advent-cal/domain';
import { AdventCal } from '@ovl/advent-cal/feature';
import React, { useEffect, useState } from 'react';
import { ToggleCap } from './toggle-cap';

const calDoorContent = {
  title: 'Batalla Fading T-Shirt',
  text:
    "Sichere dir jetzt -25% auf das offizielle T-Shirt des legendären 'Red Bull Batalla de los Gallos'-Battle. Jetzt mitmachen und Gewinncode erhalten.",
  imgUrl: '/assets/prize.jpg',
  titleClaimed: 'Batalla Fading T-Shirt',
  textClaimed:
    'Jetzt hast du es nicht mehr weit zu deinem neuen Shirt. Hier ist dein Gutscheincode:',
  smallTextClaimed:
    'Löse den Code jetzt in unserem Shop ein und zeige allen wofür dein Herz schlägt.',
  imgClaimedUrl: '/assets/prize-claimed.jpg',
  code: 'CODE-ABC-123',
  cta: {
    label: 'Shop Entdecken',
    url:
      'https://www.redbullshop.com/de-at/p/Batalla-Fading-T-Shirt/BDG19003/?preselectedVariant=M-139428',
    target: '_blank',
  },
};

const calConfig: AdventConfigDto = {
  _id: '5f8d961184078abbdfe6d5fd',
  clientId: 'red-bull-demo',
  backgroundImg: '/assets/background-blue-optimized.jpg',
  doors: [
    {
      _id: '5f8d9cbbc3e3b0c49d73e124',
      number: 3,
      size: 'medium',
      topSpace: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e125',
      number: 22,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e126',
      number: 10,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e127',
      number: 17,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e128',
      number: 19,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e129',
      number: 15,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12a',
      number: 12,
      size: 'large',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12b',
      number: 9,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12c',
      number: 5,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12d',
      number: 8,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12e',
      number: 21,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e12f',
      number: 24,
      size: 'large',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e130',
      number: 20,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e131',
      number: 1,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e132',
      number: 16,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e133',
      number: 2,
      size: 'large',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e134',
      number: 14,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e135',
      number: 13,
      size: 'small',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e136',
      number: 7,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e137',
      number: 23,
      size: 'large',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e138',
      number: 18,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e139',
      number: 4,
      size: 'large',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e13a',
      number: 11,
      size: 'medium',
      content: calDoorContent,
    },
    {
      _id: '5f8d9cbbc3e3b0c49d73e13b',
      number: 6,
      size: 'small',
      content: calDoorContent,
    },
  ],
};

export const App = () => {
  const [calendarConfig, setCalendarConfig] = useState<AdventConfigDto>();

  useEffect(() => {
    setCalendarConfig(calConfig);
  }, []);

  return calendarConfig ? (
    <AdventCal calendarConfig={calendarConfig} Toggle={ToggleCap} />
  ) : null;
};

export default App;
